/* eslint-disable sonarjs/cognitive-complexity */
'use client'

import { type GetHomePageQuery } from '@lib/__generated/sdk'
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Image from 'next/image'
import { useWindowSize } from '@components/hooks/use-window-size'

const BubbleIcon = ({
  url,
  alt,
  cn,
}: {
  url: string
  alt: string
  cn?: string
}) => (
  <Image src={url} alt={alt} width={100} height={100} className={cn} priority />
)

const BubbleTitle = ({ value, cn }: { value: string; cn?: string }) => (
  <h3 className={'font-bold ' + (cn ?? '')}>{value}</h3>
)

const BubbleDescription = ({ value, cn }: { value: string; cn?: string }) => (
  <div className={'mt-2 ' + (cn ?? '')}>{value}</div>
)

const LearnMore = ({ cn }: { cn?: string }) => (
  <div
    className={
      'mt-2 cursor-pointer uppercase underline ' +
      (cn === 'large' ? 'text-xl' : cn === 'small' ? 'text-xs' : 'text-sm')
    }
  >
    learn more
  </div>
)

const Bubble = ({
  href,
  bg,
  iconUrl,
  alt,
  title,
  description,
  itemIndex,
  bubbleCnOverride,
}: {
  href: string
  bg: string
  iconUrl: string
  alt: string
  title: string
  description: string
  itemIndex: number
  bubbleCnOverride?: string
}) => (
  <a
    href={href}
    className={`relative flex flex-col items-center justify-center rounded-full p-5 text-center shadow-xl @container ${bg} h-72 w-72 transition-all hover:scale-125`}
  >
    <BubbleIcon url={iconUrl} alt={alt} />
    <BubbleTitle value={title} />
    <BubbleDescription value={description} />
    <LearnMore />
  </a>
)

const getGridBubbleCss = (itemIndex: number, hover?: number | null) => {
  const gridItemCommonCss =
    'animate-orbit-reverse z-10 grid place-items-center '
  switch (itemIndex) {
    case 2:
      return gridItemCommonCss + 'mb-[120%]'
    case 4:
      return gridItemCommonCss + 'ml-[120%]'
    case 6:
      return gridItemCommonCss + 'mt-[120%]'
    case 8:
      return gridItemCommonCss + 'mr-[120%]'
    case 5:
      return 'animate-orbit-reverse z-10 grid place-items-center '
    default:
      throw new Error('invalid itemIndex')
  }
}

const Orbit = ({ homePage }: { homePage: GetHomePageQuery }) => {
  const size = useWindowSize()

  // for mobile
  if (size.width != null && size.width < 768) {
    // currently it is a hack to use a single image
    // TODO: it should be a component, pulling regular text and other assets from Contentful
    return (
      <a className='flex justify-center' href='/services'>
        <Image
          className='mb-12 mt-12'
          src={homePage?.pageHome?.mobileOrbit?.url!}
          alt='circle'
          width={500}
          height={500}
          priority
        />
      </a>
    )
  }

  // for tablet and desktop
  return (
    <div className='relative flex h-[1100px] w-full translate-y-[250px] flex-col items-center text-center'>
      {/*

  9 items positioned absolutely as a 3x3 grid, spinning around the center 5th item, visually appearing like this:
  1,2,3
  4,5,6
  7,8,9

  */}
      <div className='animate-orbit absolute grid h-[550px] w-[550px] place-items-center rounded-full border-4 border-dotted border-slate-300'>
        {/* 1 */}
        <div className='animate-orbit-reverse -left-[15%] -top-[5%] z-10 grid place-items-center'>
          <div className='w-[150px] text-slate-500'>
            <h4>Rapid Delivery & Innovation</h4>
          </div>
        </div>
        {/* 2 */}
        <div className={getGridBubbleCss(2)}>
          <Bubble
            href='/services/data-artificial-intelligence-and-machine-learning'
            bg='bg-bgBlue'
            iconUrl={homePage?.pageHome?.humanBrain?.url!}
            alt='humanBrain'
            title={homePage?.pageHome?.humanBrain?.title!}
            description={homePage?.pageHome?.humanBrain?.description!}
            itemIndex={2}
          />
        </div>
        {/* 3 */}
        <div className='animate-orbit-reverse -top-[5%] left-[90%] z-10 grid place-items-center'>
          <div className='w-[150px] text-slate-500'>
            <h4>Standout Customer Experiences</h4>
          </div>
        </div>
        {/* 4 */}
        <div className={getGridBubbleCss(4)}>
          <Bubble
            href='/services/customer-experience-and-design'
            bg='bg-gold'
            iconUrl={homePage?.pageHome?.humanElements?.url!}
            alt='humanElements'
            title={homePage?.pageHome?.humanElements?.title!}
            description={homePage?.pageHome?.humanElements?.description!}
            itemIndex={4}
          />
        </div>
        {/* 5 */}
        <div className={getGridBubbleCss(5)}>
          <div className='relative flex h-60 w-60 flex-col items-center justify-center rounded-full bg-black p-5 text-center text-white shadow-xl'>
            <Image
              src={homePage?.pageHome?.humanHeadBody?.url!}
              alt='humanHeadBody'
              width={32}
              height={32}
            />
            <h3 className='mt-2 uppercase'>Strategy</h3>
            <span className='text-sm'>Co-creation</span>
          </div>
        </div>
        {/* 6 */}
        <div className={getGridBubbleCss(6)}>
          <Bubble
            href='/services/modern-software-engineering'
            bg='bg-bgRed'
            iconUrl={homePage?.pageHome?.cubeIcon?.url!}
            alt='cube'
            title={homePage?.pageHome?.cubeIcon?.title!}
            description={homePage?.pageHome?.cubeIcon?.description!}
            itemIndex={6}
          />
        </div>
        {/* 7 */}
        <div className='animate-orbit-reverse -left-[15%] top-[95%] z-10 grid place-items-center'>
          <div className='w-[150px] text-slate-500'>
            <h4>Upskill Internal Teams</h4>
          </div>
        </div>
        {/* 8 */}
        <div className={getGridBubbleCss(8)}>
          <Bubble
            href='/services/cloud-platforms'
            bg='bg-bgOrange'
            iconUrl={homePage?.pageHome?.planetRing?.url!}
            alt='planetRing'
            title={homePage?.pageHome?.planetRing?.title!}
            description={homePage?.pageHome?.planetRing?.description!}
            itemIndex={8}
          />
        </div>
        {/* 9 */}
        <div className='animate-orbit-reverse left-[90%] top-[95%] z-10 grid place-items-center'>
          <div className='w-[150px] text-slate-500'>
            <h4>New Ways-of-Working</h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Orbit
