'use client'

import { type DetailedHTMLProps, type HTMLAttributes } from 'react'
import { useInView } from 'react-intersection-observer'

interface BGImgDivProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  bg: string
}

const BGImgDiv: React.FC<BGImgDivProps> = ({ bg, className, ...props }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
    rootMargin: '100px',
  })

  return (
    <div
      ref={ref}
      {...props}
      className={`${className} ${inView ? bg : 'bg-none'}`}
    />
  )
}

export default BGImgDiv
